<script setup lang="ts">
import { getRoute } from "~/lib/routes";

const { user } = useUser();
</script>

<template>
	<ClientOnly>
		<KippieLink
			:underline="false"
			:to="getRoute('account')"
			class="flex items-center gap-2 text-ellipsis"
			v-if="user"
		>
			<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[100px]">
				{{ user.firstName }}
			</div>
			<SvgIcon :name="'account'" />
		</KippieLink>
		<KippieLink :underline="false" :to="getRoute('accountLogin')" class="flex items-center gap-2" v-else>
			<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[100px]">Inloggen</div>
			<SvgIcon :name="'account'" />
		</KippieLink>

		<template #fallback>
			<KippieLink :underline="false" :to="getRoute('accountLogin')" class="flex items-center gap-2">
				<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[100px]">Inloggen</div>
				<SvgIcon :name="'account'" />
			</KippieLink>
		</template>
	</ClientOnly>
</template>
